import React, { useLayoutEffect, useEffect, useState } from 'react';
import {
  Flex,
  Tab,
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Box,
} from '@chakra-ui/core';
import { colors } from '@theme';
import Layout from '../components/layout';
import JointAccount from './joint-bank-account';
import PersonalBankAccount from './personal-bank-account';
import FamilyBankAccounts from './family-bank-account';

// This hook allows us to adjust horizontal positioning of elements responsively with respect to
// the window size.
const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window?.innerWidth, window?.innerHeight]);
    }
    window?.addEventListener('resize', updateSize);
    updateSize();
    return () => window?.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

const PageSelect = props => {
  const [tabIndex, setTabIndex] = useState(1);
  const [navLoadedPage, setNavLoadedPage] = useState(false);

  const destination = props?.location?.state?.dest;
  const [windowWidth, windowHeight] = useWindowSize();

  const handleTabsChange = index => {
    if (index === 0) {
      window?.history?.replaceState(null, '', '/personal-bank-account');
      document.title = 'Personal bank account: Simplify your finances with Zeta ';

      setTabIndex(0);
    }
    if (index === 1) {
      window?.history?.replaceState(null, '', '/joint-bank-account');
      document.title = 'Joint bank account: Simplify your finances with Zeta ';

      setTabIndex(1);
      return;
    }
    if (index === 2) {
      window?.history?.replaceState(null, '', '/family-bank-account');
      document.title = 'Family bank account: Simplify your finances with Zeta ';

      setTabIndex(2);
    }
    setTabIndex(index);
  };

  useEffect(() => {
    setNavLoadedPage(true);
    const locationPathname = props?.location?.pathname?.toString();

    if (locationPathname === '/page-select' && !navLoadedPage) {
      window?.history?.replaceState(null, '', '/joint-bank-account');
      document.title = 'Joint bank account: Simplify your finances with Zeta ';

      setTabIndex(1);
    }
    if (navLoadedPage && destination === 'family-bank-account') {
      window?.history?.replaceState(null, '', '/family-bank-account');
      document.title = 'Family bank account: Simplify your finances with Zeta ';
      setTabIndex(2);
    } else if (navLoadedPage && destination === 'personal-bank-account') {
      window?.history?.replaceState(null, '', '/personal-bank-account');
      document.title = 'Personal bank account: Simplify your finances with Zeta ';
      setTabIndex(0);
    } else if (navLoadedPage && destination === 'joint-bank-account') {
      window?.history?.replaceState(null, '', '/joint-bank-account');
      document.title = 'Joint bank account: Simplify your finances with Zeta ';
      setTabIndex(1);
    }
  }, [destination]);

  const getBackgroundGradient = idx => {
    if (idx === 0) {
      return 'linear-gradient(to bottom, #FCF8E5 0%, #FCF8E5 20%, #fff 100%)';
    } else if (idx === 1) {
      return 'linear-gradient(to bottom, #E9F8FB 0%, #E9F8FB 20%, #fff 100%)';
    } else {
      return 'linear-gradient(to bottom, #FDF3F3 0%, #FDF3F3 20%, #fff 100%)';
    }
  };

  const getNavbarColor = idx => {
    if (idx === 0) {
      return '#FCF8E5';
    } else if (idx === 1) {
      return '#E9F8FB';
    } else {
      return '#FDF3F3';
    }
  };

  let statusBarColor = getNavbarColor(tabIndex);
  const bgColorResult = result => {
    statusBarColor = result;
  };

  return (
    <Layout
      customBackgroundColor={getNavbarColor(tabIndex)}
      transparent
      mode="light"
      newNav
      noRootContainer
      bgColorResult={bgColorResult}
    >
      <Flex
        align="center"
        direction="column"
        width="100%"
        justifyContent="flex-start"
        pt={[150]}
        height={[
          windowHeight * 1 - 40,
          windowHeight * 1 - 10,
          windowHeight * 1 - 40,
          windowHeight * 1 - 40,
        ]} // please don't judge me}
        minHeight={'100%'}
        style={{
          background: getBackgroundGradient(tabIndex),
        }}
      >
        <Text
          as="h1"
          textAlign="center"
          fontSize={['40px', '60px']}
          paddingX={[6, 0]}
          lineHeight={'1.2'}
          fontWeight="600"
        >
          Zeta adapts to your life
        </Text>
      </Flex>
      <Flex
        marginTop={[`calc(${-windowHeight}px + 300px)`]}
        marginBottom={[4, 6]}
        justify={'center'}
      >
        <Text
          fontWeight={'400'}
          lineHeight={['1.2', '1.3']}
          fontSize={['18px', '24px']}
          textAlign="center"
        >
          Explore the different Zeta Accounts
        </Text>
      </Flex>
      <Tabs
        index={tabIndex}
        defaultIndex={1}
        isManual
        variant="unstyled"
        onChange={handleTabsChange}
      >
        <Flex width="100%" justify="center">
          <TabList
            display="flex"
            flexDirection={'row'}
            borderWidth={'1px'}
            borderColor={colors.greenPea}
            borderRadius="18px"
            height="fit-content"
            width={['90%', '80%', '70%', '70%']}
            maxWidth="600px"
            justifyContent="space-between"
            alignItems={['center', null]}
          >
            <Tab
              border="none"
              width={'33%'}
              maxWidth={'200px'}
              // height={['50px', 'unset']}
              borderRadius="17px"
              borderWidth={'1px'}
              color={tabIndex === 0 ? colors.white : colors.greenPea}
              backgroundColor={tabIndex === 0 ? colors.greenPea : colors.transparent}
              fontSize={['16px', '20px']}
              fontWeight={'600'}
              // marginBottom={['12px', 0]}
              _focus={{
                borderColor: 'transparent',
                outlineColor: 'transparent',
                outline: 'none',
              }}
            >
              Personal
            </Tab>
            <Tab
              border="none"
              width={'33%'}
              maxWidth={'200px'}
              // height={['50px', 'unset']}
              borderRadius="17px"
              color={tabIndex === 1 ? colors.white : colors.greenPea}
              backgroundColor={tabIndex === 1 ? colors.greenPea : colors.transparent}
              fontSize={['16px', '20px']}
              fontWeight={'600'}
              // marginBottom={['12px', 0]}
              _focus={{
                borderColor: 'transparent',
                outlineColor: 'transparent',
                outline: 'none',
              }}
            >
              Joint
            </Tab>
            <Tab
              border="none"
              width={'33%'}
              maxWidth={'200px'}
              // height={['50px', 'unset']}
              borderRadius="17px"
              color={tabIndex === 2 ? colors.white : colors.greenPea}
              backgroundColor={tabIndex === 2 ? colors.greenPea : colors.transparent}
              fontSize={['16px', '20px']}
              fontWeight={'600'}
              _focus={{
                borderColor: 'transparent',
                outlineColor: 'transparent',
                outline: 'none',
              }}
            >
              Family
            </Tab>
          </TabList>
        </Flex>

        <TabPanels>
          <TabPanel padding={0}>
            <PersonalBankAccount
              navLoaded={navLoadedPage}
              tabIndex={tabIndex}
              statusBarColor={statusBarColor}
            />
          </TabPanel>
          <TabPanel padding={0}>
            <JointAccount
              navLoaded={navLoadedPage}
              tabIndex={tabIndex}
              statusBarColor={statusBarColor}
            />
          </TabPanel>
          <TabPanel padding={0}>
            <FamilyBankAccounts
              navLoaded={navLoadedPage}
              tabIndex={tabIndex}
              statusBarColor={statusBarColor}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  );
};
export default PageSelect;
